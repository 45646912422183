<template>
  <div class="articles">
    <div class="container">
      <div class="recipes__nav">
        <locale-router-link
          to=""
          class="crumbs-link main-page-link"
        >
          {{ $t("home") }}
        </locale-router-link>
        <!-- <locale-router-link
          to="cooking"
          class="crumbs-link cooking-with-magnum"
          >{{ $t("cookingWithMagnum") }}
        </locale-router-link> -->
        <a class="crumbs-link recipes-link"> {{ $t("usefulArticles") }}</a>
      </div>
      <h2 class="articles-title">{{ $t("usefulArticles") }}</h2>
      <div class="articles__items">
        <div
          v-for="(item, index) in articles"
          :key="index"
          class="articles__item"
          :class="{ big: checkItemSize(index), hidden: item.attributes.type != 'article' }"
        >
          <locale-router-link
            :to="'useful-articles/' + item.id"
            href="#?"
          >
            <div class="articles__content">
              <p class="articles__date">
                {{ getDate(item) }}
              </p>
              <h6 class="articles__title">{{ item.attributes.header }}</h6>
            </div>
          </locale-router-link>
          <locale-router-link
            :to="'useful-articles/' + item.id"
            href="#?"
          >
            <img
              :src="getImage(item)"
              class="articles__image"
            />
          </locale-router-link>
        </div>
      </div>
      <button
        v-if="!pagination.isLastPage"
        @click="nextPage"
        class="see-all__btn"
      >
        {{ $t("catalog.more") }}
      </button>
    </div>
  </div>
</template>

<script>
import { getArticles } from "@/api/articles"
export default {
  name: "PageUsefulArticles",
  data() {
    return {
      pagination: {
        page: 1,
        pageSize: 7,
        isLastPage: false,
      },
      show: false,
      showSeeAll: false,
      articles: [],
      localMonth: {
        января: "қаңтар",
        февраля: "ақпан",
        марта: "наурыз",
        апреля: "сәуір",
        мая: "мамыр",
        июня: "маусым",
        июля: "шілде",
        августа: "тамыз",
        сентября: "қыркүйек",
        октября: "қазан",
        ноября: "қараша",
      },
    }
  },
  methods: {
    checkItemSize(e) {
      const index = e + 1
      return index % 5 === 1 || index % 5 === 2
    },
    getImage(item) {
      return this.$helpers.getAbsolutePath(item?.attributes?.image?.data?.attributes?.url)
    },
    getDate(item) {
      const dateFromStr = new Date(item.attributes.publishedAt)
      let dateRU = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateFromStr)
      let date = dateRU.split(" ")
      let dateKZ = date[0] + " " + this.localMonth[date[1]] + " " + date[2]
      if (this.$i18n.locale === "kz") {
        return dateKZ
      } else {
        return dateRU
      }
    },
    nextPage() {
      this.pagination.page++
      getArticles(this.pagination.page, this.pagination.pageSize, "article").then((res) => {
        this.articles = [...this.articles, ...res.data]
        if (this.pagination.page === res.meta.pagination.pageCount) {
          this.pagination.isLastPage = true
        } else {
          this.pagination.isLastPage = false
        }
      })
    },
  },
  created() {
    getArticles(this.pagination.page, this.pagination.pageSize, "article").then((res) => {
      this.articles = res.data
      if (this.pagination.page === res.meta.pagination.pageCount) {
        this.pagination.isLastPage = true
      } else {
        this.pagination.isLastPage = false
      }
    })
  },
}
</script>

<style
  lang="scss"
  scoped
>
.see-all__btn {
  margin-top: 40px;
}
.hidden {
  display: none;
}
</style>
